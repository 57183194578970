<template lang="html" src="./pageUserAccount.template.vue"></template>

<style lang="scss" src="./pageUserAccount.scss"></style>

<script>
import { UserProfile } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageUserAccount.i18n');

export default {
  name: 'PageUserAccount',
  components: { UserProfile },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {};
  },
};
</script>
